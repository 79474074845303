.loading-page {
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-page[aria-loading="true"] {
}

.loading-page-spitz {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 1s,
    visibility 1s;
  background-color: white;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
}

.loading-page-spitz[aria-loading="true"] {
  visibility: visible;
  opacity: 1;
}

.loading-page-foreground {
  visibility: hidden;
  width: 100vw;
  height: 100vh;
  position: absolute;
  opacity: 0;
  background-color: white;
  z-index: 99998;
  transition:
    opacity 0.6s,
    visibility 0.6s;
  pointer-events: none;
}

.loading-page-foreground[aria-loading="true"] {
  visibility: visible;
  opacity: 1;
}
