.classes-table {
  flex: 1;
  scroll-behavior: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
}

/*.classes-table .ant-table-wrapper {*/
/*  flex: 1;*/
/*  overflow: hidden;*/
/*}*/

/*.classes-table .ant-spin-nested-loading {*/
/*  display: flex;*/
/*  max-height: 100%;*/
/*  max-width: 100%;*/
/*}*/

/*.classes-table .ant-spin-container {*/
/*  max-height: 100%;*/
/*  max-width: 100%;*/
/*}*/

/*.classes-table .ant-table {*/
/*  display: flex;*/
/*  max-height: 100%;*/
/*  max-width: 100%;*/
/*}*/

/*.classes-table .ant-table-container {*/
/*  flex: 1;*/
/*  overflow: hidden;*/
/*}*/
