.users-table {
  flex: 1;
  scroll-behavior: auto;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.users-table__actions {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}

.users-table__bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0;
}