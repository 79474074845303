.report-list {
  flex: 1;
  scroll-behavior: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.report-list .ant-spin-nested-loading{
  flex: 1;
  height: 100%;
  max-height: fit-content;
}
