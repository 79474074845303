.users-selector__add-user {
  margin-top: 5px;

  display: flex;

  justify-content: stretch;
}

.users-selector__add-user button {
  flex: 1;
}
