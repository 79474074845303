.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  margin: 0;
}

.editable-cell__input {
  border-color: transparent;
}

.editable-cell__input:hover,
.editable-cell__input:focus,
.editable-cell__input:active {
  border-color: #1677ff;
}
