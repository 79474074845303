.class-group-base-modal__students-item-wrapper .ant-form-item-row {
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  column-gap: 10px;
}

.class-group-base-modal__students-item-wrapper .ant-form-item-label {
  margin: 0;
}
