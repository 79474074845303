.class-modal {
}

.class-modal-item-title {
  color: black;
  display: block;
  font-size: 14pt;
  font-weight: 600;
  margin-bottom: 5px;
}

.class-modal-item-text {
  color: black;
  font-size: 14pt;
  font-weight: 400;
  margin-bottom: 5px;
}
