.login-page {
  background: transparent !important;
}

.login-page__title {
  font-family: Young Serif, serif;
  text-align: center;
}

.login-page__layout {
  padding: 24px 0;
  background: #ffc8dd;
  max-width: 450px;
  align-self: center;
  justify-self: center;
  justify-content: center;
}

.login-page_input {
  border-color: #33282c;
}

.login-page_submit-item {
  text-align: center;
}

.login-page .spinner + .ant-spin-nested-loading {
  flex: unset;
}
