.table {
  max-height: 100%;
  flex: 1;
  height: 1px;
}

.table-content {
  max-height: 100%;
  flex: 1;
}

.table-form {
  max-height: 100%;
  display: flex;
}

.table-content .ant-spin-nested-loading {
  height: 100%;
  flex: 1;
}

.table-content .ant-spin-container {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.table-content .ant-table-fixed-header {
  max-height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.table-content .ant-table-container {
  display: flex;
  min-height: 100px;
  flex-direction: column;
  flex: 1;
}

.table-content .ant-table-header {
  min-height: 55px;
}

.table-content .ant-table-body {
  flex: 1;
    overflow-y: auto !important;
}

.table-content .ant-table-cell {
  border-bottom: none !important;
}

.table .spinner + .ant-spin-nested-loading {
  height: 100%;
  max-height: 100%;
}
