.spitz {
  transform: rotate(45deg);
  animation-name: spitz-anim;
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spitz-anim {
  0% {
    transform: rotate(45deg);
  }

  100% {
    transform: rotate(405deg);
  }
}
/* antd */
.ant-form-item-explain-error {
  font-weight: bold;
}

.ant-spin-blur::after {
  background: transparent !important;
}

.spinner + .ant-spin-nested-loading {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: max-content; /* works how I don't know */
}

.spinner + .ant-spin-nested-loading .ant-spin-container {
  max-height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}
