.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__menu {
  flex: 1;
}

.header__user {
}

.header__logout_link {
  margin-left: 20px;
}

.header__user-name {
  margin-right: 10px;
  color: white;
}

.header__menu .ant-menu-item-selected {
  background-color: #b64d71 !important;
}
