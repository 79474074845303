.not-found-page__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: none;
}

.not-found-page__content-main {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.not-found-page__content-four {
  margin: 0 25px;

  font-size: 256px;

  color: #FF5C98;
}

.not-found-page__content-text {
  margin: 0 25px;

  font-size: 48px;

  color: #FF5C98;
}

.not-found-page__content-back-link {
  margin-top: 30px;

  font-size: 48px;
}

.not-found-page__spitz {
  width: 256px;
}
