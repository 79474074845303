.calendar-page__content {
  flex-direction: row;
  justify-content: space-around;

  background: none;
}

.calendar-page__calendar {
  flex: 1 1;
  max-width: 448px;
  min-width: 448px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.calendar-page__calendar .ant-picker-cell-in-view {
  min-width: 32px;
  min-height: 32px;
  line-height: 32px;
}

.calendar-page__calendar .ant-picker-cell-inner.ant-picker-calendar-date {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.calendar-page__calendar .ant-picker-cell-inner.ant-picker-calendar-date {
  min-height: 32px;
  min-width: 32px;
}

.calendar-page__calendar .ant-picker-calendar-date-value {
  font-size: 18px;
}

.calendar-page__calendar .ant-picker-content {
  font-size: 18px;
}

.calendar-page__students_list {
  margin-left: 50px;
  max-width: 650px;
  min-width: 450px;
  border-color: black;
  border-radius: 5px;
  border-width: 10px;
  /*background: white;*/
  /*height: 80%;*/
  scroll-behavior: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.calendar-page__students_actual_list__wrapper {
  flex: 1;

  display: flex;
  flex-direction: column;

  overflow: auto;
}

.calendar-page__students_actual_list {
  /*background: white;*/
  scroll-behavior: auto;
  overflow: auto;
  background-color: white;
  padding: 0 20px;
  border-radius: 5px;
  border-width: 10px;
}

.ant-picker-calendar-mode-switch {
  visibility: hidden;
}

@media screen and (max-width: 1080px) {
  .calendar-page__content {
    max-width: 100%;

    flex-direction: column;

    align-items: center;

    justify-content: start;
  }

  .calendar-page__students_list {
    max-width: unset;

    align-self: stretch;

    margin-left: 0;
  }
}
