.page-layout {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #ffc8dd;
  overflow: auto;
}

.page-layout__content {
  flex: 1;

  align-self: stretch;

  display: flex;
  flex-direction: column;

  margin: 30px;

  max-height: calc(100% - 60px); /* 60 px is the height of top/bottom margins */
  max-width: calc(100% - 60px); /* 60 px is the width of left/right margins */

  background: white;

  border-color: black;
  border-radius: 5px;
  border-width: 10px;

  scroll-behavior: auto;
}

@media screen and (max-width: 1080px) {
    .page-layout__content {
        max-width: 100%;
      max-height: 10000px;
    }
}
